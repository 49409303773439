import { Routes } from "@angular/router";

//Route for content layout with sidebar, navbar and footer.

export const Full_ROUTES: Routes = [
  {
    path: "orders",
    loadChildren: () =>
      import("../../orders/orders.module").then((m) => m.OrdersModule),
  },
  {
    path: "employees",
    loadChildren: () =>
      import("../../management/employees/employees.module").then(
        (m) => m.EmployeesModule
      ),
  },
  {
    path: "base-products",
    loadChildren: () =>
      import("../../management/base-products/base-products.module").then(
        (m) => m.BaseProductsModule
      ),
  },
  {
    path: "products",
    loadChildren: () =>
      import("../../management/products/products.module").then(
        (m) => m.ProductsModule
      ),
  },
  {
    path: "orders",
    loadChildren: () =>
        import("../../management/orders/orders.module").then(
            (m) => m.OrdersModule
        ),
  },
  {
    path: "customers",
    loadChildren: () =>
        import("../../management/customers/customers.module").then(
            (m) => m.CustomersModule
        ),
  },
  {
    path: "dashboard",
    loadChildren: () =>
      import("../../dashboard/dashboard.module").then((m) => m.DashboardModule),
  },
  {
    path: "calendar",
    loadChildren: () =>
      import("../../calendar/calendar.module").then((m) => m.CalendarsModule),
  },
  {
    path: "charts",
    loadChildren: () =>
      import("../../charts/charts.module").then((m) => m.ChartsNg2Module),
  },
  {
    path: "forms",
    loadChildren: () =>
      import("../../forms/forms.module").then((m) => m.FormModule),
  },
  {
    path: "maps",
    loadChildren: () =>
      import("../../maps/maps.module").then((m) => m.MapsModule),
  },
  {
    path: "tables",
    loadChildren: () =>
      import("../../tables/tables.module").then((m) => m.TablesModule),
  },
  {
    path: "datatables",
    loadChildren: () =>
      import("../../data-tables/data-tables.module").then(
        (m) => m.DataTablesModule
      ),
  },
  {
    path: "uikit",
    loadChildren: () =>
      import("../../ui-kit/ui-kit.module").then((m) => m.UIKitModule),
  },
  {
    path: "components",
    loadChildren: () =>
      import("../../components/ui-components.module").then(
        (m) => m.UIComponentsModule
      ),
  },
  {
    path: "pages",
    loadChildren: () =>
      import("../../pages/full-pages/full-pages.module").then(
        (m) => m.FullPagesModule
      ),
  },
  {
    path: "cards",
    loadChildren: () =>
      import("../../cards/cards.module").then((m) => m.CardsModule),
  },
  {
    path: "colorpalettes",
    loadChildren: () =>
      import("../../color-palette/color-palette.module").then(
        (m) => m.ColorPaletteModule
      ),
  },
  {
    path: "chat",
    loadChildren: () =>
      import("../../chat/chat.module").then((m) => m.ChatModule),
  },
  {
    path: "chat-ngrx",
    loadChildren: () =>
      import("../../chat-ngrx/chat-ngrx.module").then((m) => m.ChatNGRXModule),
  },
  {
    path: "inbox",
    loadChildren: () =>
      import("../../inbox/inbox.module").then((m) => m.InboxModule),
  },
  {
    path: "taskboard",
    loadChildren: () =>
      import("../../taskboard/taskboard.module").then((m) => m.TaskboardModule),
  },
  {
    path: "taskboard-ngrx",
    loadChildren: () =>
      import("../../taskboard-ngrx/taskboard-ngrx.module").then(
        (m) => m.TaskboardNGRXModule
      ),
  },
  {
    path: "player",
    loadChildren: () =>
      import("../../player/player.module").then((m) => m.PlayerModule),
  },
];
