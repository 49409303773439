import {
  Component,
  Output,
  EventEmitter,
  OnDestroy,
  OnInit,
  AfterViewInit,
} from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { LayoutService } from "../services/layout.service";
import { Subscription } from "rxjs";
import { ConfigService } from "../services/config.service";
import { AuthService } from "../auth/auth.service";
import { Router } from "@angular/router";
import { DataService } from "@model/services/data.services";
import * as _ from 'underscore';

@Component({
  selector: "app-navbar",
  templateUrl: "./navbar.component.html",
  styleUrls: ["./navbar.component.scss"],
})
export class NavbarComponent implements OnInit, AfterViewInit, OnDestroy {
  userData = JSON.parse(localStorage.getItem("usrData"));
  userProfileLink = `/employees/${this.userData.id}`;
  currentLang = "en";
  toggleClass = "ft-maximize";
  placement = "bottom-right";
  public isCollapsed = true;
  layoutSub: Subscription;
  @Output()
  toggleHideSidebar = new EventEmitter<Object>();
  hasSearchResults:boolean = false;
  searchResults: any[] = [];
  searchType: string;

  public config: any = {};

  constructor(
    public translate: TranslateService,
    private layoutService: LayoutService,
    private configService: ConfigService,
    private authService: AuthService,
    private router: Router,
    private data$: DataService
  ) {
    const browserLang: string = translate.getBrowserLang();
    translate.use(browserLang.match(/en|es|pt|de/) ? browserLang : "en");

    this.layoutSub = layoutService.changeEmitted$.subscribe((direction) => {
      const dir = direction.direction;
      if (dir === "rtl") {
        this.placement = "bottom-left";
      } else if (dir === "ltr") {
        this.placement = "bottom-right";
      }
    });

    // Debounce search method
    this.doSearch = _.debounce(this.doSearch, 1000)
  }

  ngOnInit() {
    this.config = this.configService.templateConf;
  }

  ngAfterViewInit() {
    if (this.config.layout.dir) {
      setTimeout(() => {
        const dir = this.config.layout.dir;
        if (dir === "rtl") {
          this.placement = "bottom-left";
        } else if (dir === "ltr") {
          this.placement = "bottom-right";
        }
      }, 0);
    }
  }

  ngOnDestroy() {
    if (this.layoutSub) {
      this.layoutSub.unsubscribe();
    }
  }

  ChangeLanguage(language: string) {
    this.translate.use(language);
  }

  ToggleClass() {
    if (this.toggleClass === "ft-maximize") {
      this.toggleClass = "ft-minimize";
    } else {
      this.toggleClass = "ft-maximize";
    }
  }

  toggleNotificationSidebar() {
    this.layoutService.emitNotiSidebarChange(true);
  }

  toggleSidebar() {
    const appSidebar = document.getElementsByClassName("app-sidebar")[0];
    if (appSidebar.classList.contains("hide-sidebar")) {
      this.toggleHideSidebar.emit(false);
    } else {
      this.toggleHideSidebar.emit(true);
    }
  }

  logOut() {
    this.authService.logout();
    this.router.navigateByUrl("/login");
  }

  doSearch(term: any) {
    
    this.searchType = null
    if(term.length > 0){
      // Determine endpoint
      switch (true) {
        case isNaN(term):
          this.searchType = "customer"
          break;
        default:
          this.searchType = "order"
          break;
      }
      
      // Make request
      this.data$.search(term, this.searchType).subscribe((res:any) => {
       if(res.length > 0){
        this.searchResults = []
        this.hasSearchResults = true
        // Limit to 5 results
        res.forEach(item => {
        if(this.searchResults.length < 5){ 
            this.searchResults.push(item)
          }else{
          return
          }
        });
       } else{
         this.hasSearchResults = false
         this.searchResults = []
         
       }
       console.log(this.searchResults)
      })
    }
  }

  // Redirect to target
  navigateResult(target: string){
    this.hasSearchResults = false
    this.router.navigate([target])
  }
}
