import { Injectable } from "@angular/core";
import swal, { SweetAlertIcon } from "sweetalert2";
import {BaseProduct} from '../../management/base-products/base-product.model';

@Injectable({
  providedIn: "root",
})
export class UtilsService {
  
  // Crear alerta de confirmación
  createConfirmModal(title: string, text: string, icon: SweetAlertIcon, confirmBtn: string, cancelBtn: string, returnFn: any, cancelReturnFn?: any) {
    swal.fire({
      title: title,
      text: text,
      icon: icon,
      showCancelButton: true,
      confirmButtonColor: '#0CC27E',
      cancelButtonColor: '#FF586B',
      confirmButtonText: confirmBtn,
      cancelButtonText: cancelBtn,
      customClass: {
          confirmButton: 'btn btn-success btn-raised mr-5',
          cancelButton: 'btn btn-danger btn-raised'
        },
      buttonsStyling: false
    }).then(function (event) {
      if (typeof event.value !== 'undefined' && event.value) returnFn(event);
      else cancelReturnFn(event);
    });
  }

  // Crear alerta de información. Tipos: ["question", "success", "info", "warning", "error"]
  createAlertModal(title: string, text: string, type: SweetAlertIcon, returnFn) {
    swal.fire(title, text, type).then((response) => returnFn(response));
  }

  parseBaseProduct(bpData: BaseProduct) {
    const baseProduct = {
      'name': bpData.name,
      'price': bpData.price,
      'process_select': bpData.process,
      'default_process': bpData.defaultProcess,
      'scan_size_select': bpData.scanSize,
      'scanner_preference_select': bpData.scannerPreference,
      'push_pull_select': bpData.push,
      'proof_prints_select': bpData.proofPrints,
      'rush_select': bpData.rush,
      'format_select': bpData.format,
      'service_options_select': bpData.serviceOptions,
      'product_category_id': bpData.productCategory,
      'negative_archive': bpData.negativeArchive,
      'feedback': bpData.feedback,
      'color': bpData.color,
      'exposure': bpData.exposure,
      'contrast': bpData.contrast,
      'density': bpData.density,
      'a_tech': bpData.aTech,
      'cut': bpData.cut
    }
    return baseProduct;
  }
}
