import {HttpClient, HttpErrorResponse, HttpHeaders} from '@angular/common/http';
import { Injectable } from '@angular/core';
import {Observable, throwError, of} from 'rxjs';
import { map, catchError, debounce, debounceTime } from 'rxjs/operators';
import { BaseProduct } from '../../management/base-products/base-product.model';
import { UtilsService } from '@model/services/utils.services';

@Injectable({
  providedIn: 'root',
})
export class DataService {
  urlAPI: string = 'https://tfl.innobing.net';
  authToken: string;
  httpOptions: any;
  modifiers: any;
  auth: string = localStorage.getItem('usrToken');

  constructor(private http: HttpClient, private utilsService: UtilsService) {
    if (this.auth !== 'undefined' && this.auth !== null) {
      this.authToken = this.auth;
      console.log("authToken::", this.authToken)
      this.httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': this.authToken,
        }),
      };

    }else{
      // log user out
    }
    
  }

  // Temp solution
  getMediaUrl(){
    return this.urlAPI  
  }

  getModifiers(){
    
    return this.http
    .get(`${this.urlAPI}/modifiers.json`, this.httpOptions)
    .pipe(
        map((res) => res ),
        catchError((err) => err)
    );
  }


  getDepartments() {
    return [
      {
        id: 1,
        name: 'Customer service',
      },
      {
        id: 2,
        name: 'Developing',
      },
      {
        id: 3,
        name: 'Scanning',
      },
      {
        id: 4,
        name: 'Editing',
      },
      {
        id: 5,
        name: 'Archiving',
      },
      {
        id: 6,
        name: 'Management',
      },
    ];
  }

  getRoles() {
    return [
      {
        id: 1,
        name: 'Employee',
      },
      {
        id: 2,
        name: 'Manager',
      },
      {
        id: 3,
        name: 'Admin',
      },
    ];
  }

  getPositions() {
    return [
      {
        id: 1,
        name: 'Remote',
      },
      {
        id: 2,
        name: 'Inhouse',
      },
    ];
  }

  getNegativeArchiveContent() {
    return [
      {
        name: 'Archive for 90 days',
        default: false
      },
      {
        name: 'Ship ASAP',
        default: false
      },
      {
        name: 'Destroy after scan',
        default: true
      },
      {
        name: 'Local pick-up',
        default: false
      }
    ]
  }

  getFeedbackContent() {
    return [
      {
        name: 'No',
        default: true
      },
      {
        name: 'Yes',
        default: false
      }
    ]
  }

  getColorContent() {
    return [
      {
        name: 'Cool',
        default: false
      },
      {
        name: 'Neutral',
        default: true
      },
      {
        name: 'Warm',
        default: false
      }
    ]
  }

  getExposureContent() {
    return [
      {
        name: 'Brighter',
        default: false
      },
      {
        name: 'Neutral',
        default: true
      },
      {
        name: 'Dark',
        default: false
      }
    ]
  }

  getDensityContent() {
    return [
      {
        name: 'Brighter',
        default: false
      },
      {
        name: 'Neutral',
        default: true
      },
      {
        name: 'Darker',
        default: false
      }
    ]
  }

  getContrastContent() {
    return [
      {
        name: 'Low',
        default: false
      },
      {
        name: 'Neutral',
        default: true
      },
      {
        name: 'High',
        default: false
      }
    ]
  }

  getSelectATechContent() {
    return [
      {
        name: 'I\'m not enrolled',
        default: true
      },
      {
        name: 'I am enrolled in Select-A-Tech',
        default: false
      }
    ]
  }

  getCutContent() {
    return [
      {
        name: 'Uncut',
        default: true
      },
      {
        name: 'Cut',
        default: false
      },
      
    ]
  }


  // Search orders & customers
  search(term:any, type: string) {
    var data = type === "order" ? {'order_id': term} : {'search': term}
    var endpoint = type === "order" ? "order/find_by_id" : "customer/finder"
   
    return this.http
        .post(`${this.urlAPI}/${endpoint}.json`, data, this.httpOptions)
        .pipe(
            map((res) => res),
            catchError((err) => err)
        );
  }
  
  // Identificar un usuario
  login(userData): Observable<any> {
    return this.http
      .post(`${this.urlAPI}/login`, userData, { observe: 'response' })
      .pipe(
        map((res) => res),
        catchError((err) => {
          return throwError(err);
        })
      );
  }

  // Obtener todos los usuarios
  getAllEmployees(): Observable<any> {
    return this.http
      .get(`${this.urlAPI}/users.json`, this.httpOptions)
      .pipe(
        map((res) => res),
        catchError((err) => err)
      );
  }

  // Obtener usuario por ID
  getEmployee(id): Observable<any> {
    return this.http
      .get(`${this.urlAPI}/users/${id}.json`, this.httpOptions)
      .pipe(
        map((res) => res),
        catchError((err) => err.json())
      );
  }

  // Crear usuario
  createEmployee(userData: any): Observable<any> {
    return this.http
      .post(`${this.urlAPI}/signup`, userData, this.httpOptions)
      .pipe(
        map((res) => res),
        catchError((err) => err.json())
      );
  }

  // Editar usuario
  editEmployee(userData: any, id: number): Observable<any> {
    return this.http
      .patch(`${this.urlAPI}/users/${id}.json`, userData, this.httpOptions)
      .pipe(
        map((res) => res),
        catchError((err) => err.json())
      );
  }

  // Borrar usuario
  deleteEmployee(userId: string): Observable<any> {
    return this.http
      .delete(`${this.urlAPI}/users/${userId}.json`, this.httpOptions)
      .pipe(
        map((res) => res),
        catchError((err) => err.json())
      );
  }

  // Obtener atributos de caregorias
  getAttributeCategories(): Observable<any> {
    return this.http
      .get(`${this.urlAPI}/attribute_categories.json`, this.httpOptions)
      .pipe(
        map((res) => res),
        catchError((err) => err)
      );
  }

  // Create product
  createProduct(product): Observable<any> {
    
    return this.http
      .post(`${this.urlAPI}/products.json`, {'product': product}, this.httpOptions)
      .pipe(
        map((res) => res),
        catchError((err) => err)
      );
  }

  // Obtener products
  getAvailableProducts(): Observable<any> {
    return this.http
      .get(`${this.urlAPI}/products.json`, this.httpOptions)
      .pipe(
        map((res) => res),
        catchError((err) => err)
      );
  }

  // Obtener products
  findProducts(term:string): Observable<any> {
    return this.http
      .get(`${this.urlAPI}/products.json?search=${term}`, this.httpOptions)
      .pipe(
        map((res) => res),
        catchError((err) => {
          return throwError(err)
        })
      );
  }

  // Obtener product categories
  getProductCategories(): Observable<any> {
    return this.http
      .get(`${this.urlAPI}/product_categories.json`, this.httpOptions)
      .pipe(
        map((res) => res),
        catchError((err) => err)
      )
  }

  // Get product types
  getProductTypes(): Observable<any> {
    return this.http
      .get(`${this.urlAPI}/product_types.json`, this.httpOptions)
      .pipe(
        map((res) => res),
        catchError((err) => err)
      )
  }

  // Get base product by ID
  getProductById(id: string): Observable<any> {
    return this.http
      .get(`${this.urlAPI}/products/${id}.json`, this.httpOptions)
      .pipe(
        map((res) => res),
        catchError(err => err)
      );
  }

  // Update product visibility
  updateProduct(product): Observable<any> {
    return this.http
      .patch(`${this.urlAPI}/products/${product.id}.json`, { 'product': product }, this.httpOptions)
      .pipe(
        map((res) => res),
        catchError(err => err)
      );
  }

  // Edit base product by ID
  editBaseProductById(id: string, bProdData: BaseProduct): Observable<any> {
    const baseProduct = this.utilsService.parseBaseProduct(bProdData);
    return this.http
      .patch(`${this.urlAPI}/base_products/${id}.json`, { 'base_product': baseProduct }, this.httpOptions)
      .pipe(
        map((res) => res),
        catchError((err) => err)
      );
  }

  // PRODUCT OPTIONS
  
  // Create product option
  createOption(option): Observable<any> {
    return this.http
      .post(`${this.urlAPI}/product_options.json`, {'product_option': option}, this.httpOptions)
      .pipe(
        map((res) => res),
        catchError((err) => err)
      );
  }

  // Update product option
  updateOption(option): Observable<any> {
    return this.http
      .patch(`${this.urlAPI}/product_options/${option.id}.json`, { 'product_option': option }, this.httpOptions)
      .pipe(
        map((res) => res),
        catchError(err => err)
      );
  }

  // Delete product option
  deleteOption(optionId): Observable<any> {
    return this.http
      .delete(`${this.urlAPI}/product_options/${optionId}.json`, this.httpOptions)
      .pipe(
        map((res) => res),
        catchError((err) => err)
      );
  }

  // PRODUCT OPTION VALUES
  // Create product option
  createOptionValue(optionValue): Observable<any> {
    console.log(optionValue);
    return this.http
      .post(`${this.urlAPI}/product_option_values.json`, {'product_option_value': optionValue}, this.httpOptions)
      .pipe(
        map((res) => res),
        catchError((err) => err)
      );
  }

  // Update product option value
  updateOptionValue(value): Observable<any> {
    return this.http
      .patch(`${this.urlAPI}/product_option_values/${value.id}.json`, { 'product_option_value': value }, this.httpOptions)
      .pipe(
        map((res) => res),
        catchError(err => err)
      );
  }

  // Delete product option
  deleteOptionValue(valueId): Observable<any> {
    return this.http
      .delete(`${this.urlAPI}/product_option_values/${valueId}.json`, this.httpOptions)
      .pipe(
        map((res) => res),
        catchError((err) => err)
      );
  }


  //// CUSTOMER METHODS ////
  // Obtener todos los usuarios
  findCustomers(term:string): Observable<any> {
    return this.http
      .get(`${this.urlAPI}/customers.json?search=${term}`, this.httpOptions)
      .pipe(
        map((res) => res),
        catchError((err) => err)
      );
  }

  // Customers with active orders
  getAllCustomers(): Observable<any> {
    return this.http
      .get(`${this.urlAPI}/customers_with_open_orders.json`, this.httpOptions)
      .pipe(
        map((res) => res),
        catchError((err) => err)
      );
  }

  // Obtener customer por ID
  getCustomer(id): Observable<any> {
    return this.http
      .get(`${this.urlAPI}/customers/${id}.json`, this.httpOptions)
      .pipe(
        map((res) => res),
        catchError((err) => err)
      );
  }

  // Get all orders for customer
  getCustomerOrders(id): Observable<any> {
    return this.http
      .post(`${this.urlAPI}/get_customer_orders.json`, { customer_id: id }, this.httpOptions)
      .pipe(
        map((res) => res),
        catchError((err) => err)
      );
  }

  // Crear customer
  createCustomer(customerData: any): Observable<any> {
    return this.http
      .post(`${this.urlAPI}/customers.json`, customerData, this.httpOptions)
      .pipe(
        map((res) => res),
        catchError((err) => err.json())
      );
  }

  // Editar customer
  editCustomer(customerData: any, id: number): Observable<any> {
    return this.http
      .put(`${this.urlAPI}/customers/${id}.json`, customerData, this.httpOptions)
      .pipe(
        map((res) => res),
        catchError((err) => err)
      );
  }

  // Borrar customer
  deleteCustomer(customerId: string): Observable<any> {
    console.log(customerId);

    return this.http
      .delete(`${this.urlAPI}/customers/${customerId}.json`, this.httpOptions)
      .pipe(
        map((res) => res),
        catchError((err) => err)
      );
  }

  // GET ALL ORDERS
  getAllOrders(): Observable<any> {
    return this.http
      .get(`${this.urlAPI}/orders.json`, this.httpOptions)
      .pipe(
        map((res) => res),
        catchError((err) => err)
      );
  }

  // CREATE ORDER
  createOrder(orderData: any): Observable<any> {
    const order = {
      'customer_id': orderData.customerId,
      'shot_name': orderData.shotName,
      'author': orderData.author,
      'product_id': orderData.productId,
      'product_option_values': orderData.optionValues,
      'quantity': orderData.quantity
    }
    return this.http
      .post(`${this.urlAPI}/orders.json`, order , this.httpOptions)
      .pipe(
        map((res) => res),
        catchError((err) => err)
      );
  }

  // GET ORDER BY ID
  getOrderById(orderId: string): Observable<any> {
    return this.http
      .get(`${this.urlAPI}/orders/${orderId}.json`, this.httpOptions)
      .pipe(
        map((res) => res),
        catchError((err) => err)
      );
  }

  // GET CUSTOMER CARDS
  getAllCustomerCards(customerId: string): Observable<any> {
    const objCustomerId = { 'customer_id': customerId };
    return this.http
      .post(`${this.urlAPI}/get_customer_cards.json`, objCustomerId, this.httpOptions)
      .pipe(
        map((res) => res),
        catchError(err => {
          let errorMsg: string;
          if (err.error instanceof ErrorEvent) {
            errorMsg = `Error: ${err.error.message}`;
          } else {
            errorMsg = this.getServerErrorMessage(err);
          }
          return throwError(errorMsg);
        })
      );
  }

  // PAY ALL CUSTOMER ORDERS
  createPayOrders(orderIds: string[], payData: any): Observable<any> {
    let orderData;
    if (payData.name && payData.number && payData.month && payData.year && payData.cvc) {
      orderData = {
        'order_ids': orderIds,
        'name': payData.name ? payData.name : null,
        'cc': payData.number,
        'exp_month': payData.month,
        'exp_year': payData.year,
        'cvc': payData.cvc
      }
    } else {
      orderData = {
        'order_ids': orderIds,
        'card_id': payData
      }
    }

    return this.http
      .post(`${this.urlAPI}/pay_orders.json`, orderData, this.httpOptions)
      .pipe(
        map((res) => res),
        catchError((err) => err)
      );
  }

  // DELETE AN ORDER BY ID
  deleteOrder(orderId: string): Observable<any> {
    return this.http
      .delete(`${this.urlAPI}/orders/${orderId}.json`, this.httpOptions)
      .pipe(
        map((res) => res),
        catchError((err) => err)
      );
  }

  // Create posts
  postIt(post) {
    let data = {user_id: post.postAuthor, order_id: post.orderId, post_type: post.postType,post_content: post.postContent}
    return this.http
        .post(`${this.urlAPI}/posts.json`, data, this.httpOptions)
        .pipe(
            map((res) => res),
            catchError((err) => err)
        );
  }

  // ERRORS //
  private getServerErrorMessage(error: HttpErrorResponse): string {
    switch (error.status) {
      case 404: {
        return `Not found: ${error.message}`;
      }
      case 403: {
        return `Access denied: ${error.message}`;
      }
      case 500: {
        return `Internal server error: ${error.message}`;
      }
      default: {
        return `Unknown error: ${error.message}`;
      }
    }
  }
}
