import {
  Component,
  OnInit,
  Input,
  ViewChild,
  OnDestroy,
  ElementRef,
  Renderer2,
  AfterViewInit,
} from "@angular/core";

import { ROUTES } from "./sidebar-routes.config";
import { RouteInfo } from "./sidebar.metadata";
import { Router, ActivatedRoute } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { customAnimations } from "../animations/custom-animations";
import { ConfigService } from "../services/config.service";
import { LayoutService } from "../services/layout.service";
import { Subscription } from "rxjs";

@Component({
  selector: "app-sidebar",
  templateUrl: "./sidebar.component.html",
  animations: customAnimations,
})
export class SidebarComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild("toggleIcon") toggleIcon: ElementRef;
  allRoutes: RouteInfo[];
  public menuItems: any[] = [];
  userPermissions: {role: string, department: string};
  depth: number;
  activeTitle: string;
  activeTitles: string[] = [];
  expanded: boolean;
  nav_collapsed_open = false;
  logoUrl = "/assets/img/logo.jpg";
  public config: any = {};
  layoutSub: Subscription;

  constructor(
    private elementRef: ElementRef,
    private renderer: Renderer2,
    private router: Router,
    private route: ActivatedRoute,
    public translate: TranslateService,
    private configService: ConfigService,
    private layoutService: LayoutService
  ) {
    if (this.depth === undefined) {
      this.depth = 0;
      this.expanded = true;
    }

    this.layoutSub = layoutService.customizerChangeEmitted$.subscribe(
      (options) => {
        if (options) {
          if (options.bgColor) {
            if (options.bgColor === "white") {
              // this.logoUrl = "assets/img/logo-dark.png";
              this.logoUrl = "/assets/img/logo.jpg";
            } else {
              // this.logoUrl = "assets/img/logo.png";
              this.logoUrl = "/assets/img/logo.jpg";
            }
          }

          if (options.compactMenu === true) {
            this.expanded = false;
            this.renderer.addClass(
              this.toggleIcon.nativeElement,
              "ft-toggle-left"
            );
            this.renderer.removeClass(
              this.toggleIcon.nativeElement,
              "ft-toggle-right"
            );
            this.nav_collapsed_open = true;
          } else if (options.compactMenu === false) {
            this.expanded = true;
            this.renderer.removeClass(
              this.toggleIcon.nativeElement,
              "ft-toggle-left"
            );
            this.renderer.addClass(
              this.toggleIcon.nativeElement,
              "ft-toggle-right"
            );
            this.nav_collapsed_open = false;
          }
        }
      }
    );
  }

  ngOnInit() {
    this.config = this.configService.templateConf;

    // Build side menu
    const usr = JSON.parse(localStorage.getItem("usrData"));
    this.userPermissions = {role: usr.role, department: usr.department};
    this.allRoutes = ROUTES;
    this.allRoutes.forEach((department) => {
     
        // Check item access
        var allowedMenuItems = [];

        department.submenu.forEach((item)=>{
          
          if(this.grantItemMenuAccess(this.userPermissions.role, item.userRole)){
            allowedMenuItems.push(item);
          }
        });
       
        department.submenu = [];
        department.submenu = allowedMenuItems;

        if(allowedMenuItems.length > 0) this.menuItems.push(department);
    })
    


    if (this.config.layout.sidebar.backgroundColor === "white") {
      this.logoUrl = "/assets/img/logo.jpg";
    } else {
      this.logoUrl = "/assets/img/logo.jpg";
    }
  }

  ngAfterViewInit() {
    setTimeout(() => {
      if (this.config.layout.sidebar.collapsed != undefined) {
        if (this.config.layout.sidebar.collapsed === true) {
          this.expanded = false;
          this.renderer.addClass(
            this.toggleIcon.nativeElement,
            "ft-toggle-left"
          );
          this.renderer.removeClass(
            this.toggleIcon.nativeElement,
            "ft-toggle-right"
          );
          this.nav_collapsed_open = true;
        } else if (this.config.layout.sidebar.collapsed === false) {
          this.expanded = true;
          this.renderer.removeClass(
            this.toggleIcon.nativeElement,
            "ft-toggle-left"
          );
          this.renderer.addClass(
            this.toggleIcon.nativeElement,
            "ft-toggle-right"
          );
          this.nav_collapsed_open = false;
        }
      }
    }, 0);
  }

  ngOnDestroy() {
    if (this.layoutSub) {
      this.layoutSub.unsubscribe();
    }
  }

  toggleSlideInOut() {
    this.expanded = !this.expanded;
  }

  handleToggle(titles) {
    this.activeTitles = titles;
  }

  // NGX Wizard - skip url change
  ngxWizardFunction(path: string) {
    if (path.indexOf("forms/ngx") !== -1)
      this.router.navigate(["forms/ngx/wizard"], { skipLocationChange: false });
  }

  // Grant user permission to menu if admin or user belongs to department
  grantMenuAccess(userData, menuDepartment): boolean{
    var userRole = userData.role;
    var userLevel;
    var userDepartment = userData.department;
    switch (userRole) {
      case "Admin":
        userLevel = 3;
        break;
      case "Manager":
        userLevel = 2;
        break;
      default:
        userLevel = 1;
        break;
    }
    if(userDepartment != menuDepartment || userLevel != 3){
      return false;
    } else{
      return true;
    }
   
  }

  // Grant user permission to item if role is employee or menu role
  grantItemMenuAccess(userRole, menuRole): boolean{
    var userRole = userRole;
    var userLevel;
    switch (userRole) {
      case "Admin":
        userLevel = 3;
        break;
      case "Manager":
        userLevel = 2;
        break;
      default:
        userLevel = 1;
        break;
    }

    
    if(menuRole != null && userRole != menuRole){
      console.log(userRole);
      console.log(menuRole);
      console.log("FALSE");
      return false;
    } else{
      return true;
    }
   
  }
}
