import { Route } from "@angular/compiler/src/core";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { DataService } from "@model/services/data.services";
import { UtilsService } from "@model/services/utils.services";
import { Observable } from "rxjs";

@Injectable()
export class AuthService {
  token: string;

  constructor(
    private dataService: DataService,
    private router: Router,
    private utils: UtilsService
  ) {
    this.token = localStorage.getItem("usrToken");
  }

  signupUser(email: string, password: string) {
    //your code for signing up the new user
  }

  signinUser(email: string, password: string) {
    //your code for checking credentials and getting tokens for for signing in user

    this.dataService.login({ email: email, password: password }).subscribe(
      (res) => {
        localStorage.setItem("usrToken", res.headers.get("Authorization"));

        localStorage.setItem("usrData", JSON.stringify(res.body));
        this.router.navigateByUrl("/");
      },
      (err) => {
        console.log(err);
        this.utils.createAlertModal(
          "Error",
          "Incorrect email or password",
          "error",
          () => {}
        );
      }
    );
  }

  logout() {
    localStorage.removeItem("usrToken");
    localStorage.removeItem("usrData");
    this.token = null;
  }

  getToken() {
    return localStorage.getItem("usrToken");
  }

  isAuthenticated() {
    // here you can check if user is authenticated or not through his token
    if (localStorage.getItem("usrToken")) {
      return true;
    } else {
      return false;
    }
  }
}
