import { Component, ViewChild } from "@angular/core";
import { NgForm } from "@angular/forms";
import { OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { AuthService } from "app/shared/auth/auth.service";
import { UtilsService } from "@model/services/utils.services";

@Component({
  selector: "app-login-page",
  templateUrl: "./login-page.component.html",
  styleUrls: ["./login-page.component.scss"],
})
export class LoginPageComponent implements OnInit {
  // @ViewChild("f") loginForm: NgForm;
  formData = {
    email: null,
    password: null,
  };
  usrToken: string;
  submittedData: boolean = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private authService: AuthService,
    private utils: UtilsService
  ) {
    this.usrToken = this.authService.getToken();
    if (this.usrToken) {
      this.router.navigateByUrl("/");
    }
  }

  ngOnInit() {}

  // On submit button click
  onSubmit() {
    
    if (this.formData.email && this.formData.password) {
      this.authService.signinUser(this.formData.email, this.formData.password);
      this.submittedData = false;
    } else {
      this.utils.createAlertModal(
        "Error",
        "Missing Data, please check email and password",
        "error",
        () => {}
      );
      this.submittedData = false;
  }
  }
  // On Forgot password link click
  onForgotPassword() {
    this.router.navigate(["forgotpassword"], { relativeTo: this.route.parent });
  }
  // On registration link click
  onRegister() {
    this.router.navigate(["register"], { relativeTo: this.route.parent });
  }
}
