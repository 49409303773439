<!--Login Page Starts-->
<section id="login">
  <div class="container-fluid">
    <div class="row full-height-vh m-0">
      <div class="col-12 d-flex align-items-center justify-content-center">
        <div class="card">
          <div class="card-content">
            <div class="card-body login-img">
              <div class="row m-0">
                <div
                  class="col-lg-6 d-lg-block d-none py-2 px-3 text-center align-middle"
                >
                  <img
                    src="../../assets/img/gallery/login.png"
                    alt=""
                    class="img-fluid mt-5"
                    width="400"
                    height="230"
                  />
                </div>
                <div class="col-lg-6 col-md-12 bg-white px-4 pt-3">
                  <h4 class="card-title mb-2">Login</h4>
                  <p class="card-text mb-3">
                    Welcome back, please login to your account.
                  </p>
                  <form>  
                  <input
                    type="text"
                    class="form-control mb-3"
                    placeholder="Email"
                    autofocus
                    #email
                    (change)="formData.email = email.value"
                  />
                  <input
                    type="password"
                    class="form-control mb-3"
                    placeholder="Password"
                    #password
                    (change)="formData.password = password.value"
                  />

                  <!-- <div class="d-flex justify-content-between mt-2">
                    <div class="remember-me">
                      <div
                        class="custom-control custom-checkbox custom-control-inline mb-3"
                      >
                        <input
                          type="checkbox"
                          id="customCheckboxInline1"
                          name="customCheckboxInline1"
                          class="custom-control-input"
                        />
                        <label
                          class="custom-control-label"
                          for="customCheckboxInline1"
                        >
                          Remember Me
                        </label>
                      </div>
                    </div>
                    <div class="forgot-password-option">
                      <a
                        [routerLink]="['/pages/forgotpassword']"
                        class="text-decoration-none text-primary"
                        >Forgot Password ?</a
                      >
                    </div>
                  </div> -->
                  <div class="fg-actions d-flex justify-content-between">
                    <!-- <div class="login-btn">
                      <button class="btn btn-outline-primary">
                        <a
                          [routerLink]="['/pages/register']"
                          class="text-decoration-none"
                          >Register</a
                        >
                      </button>
                    </div> -->
                    <div class="recover-pass">
                      <button
                        class="btn btn-primary btn-block"
                        (click)="onSubmit()"
                        (click)="submittedData=!submittedData"
                        *ngIf="!submittedData"
                      >
                        <a class="text-decoration-none text-white">Login</a>
                      </button>
                      <button
                        class="btn btn-primary btn-block"
                        *ngIf="submittedData"
                      >
                        <a class="text-decoration-none text-white"
                          >Loading...</a
                        >
                      </button>
                    </div>
                  </div>
                </form>
                  <!-- <hr class="m-0" />
                  <div class="d-flex justify-content-between mt-3">
                    <div class="option-login">
                      <h6 class="text-decoration-none text-primary">
                        Or Login With
                      </h6>
                    </div>
                    <div class="social-login-options">
                      <a class="btn btn-social-icon mr-2 btn-facebook">
                        <span class="fa fa-facebook"></span>
                      </a>
                      <a class="btn btn-social-icon mr-2 btn-twitter">
                        <span class="fa fa-twitter"></span>
                      </a>
                    </div>
                  </div> -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!--Login Page Ends-->
